import _ from 'lodash';
import moment from 'moment';

import {defineMessages, FormattedMessage} from 'react-intl';

import {ALL_CATEGORIES_GUID} from '../constants/categories';
import {ALERT_SEVERITIES} from '../constants/alert-severity-keys';
import {AIRLINE_RATINGS} from '../constants/world-airlines';
import {inversePersonLocationTypeMap, NOTIFICATION_TYPES} from '../redux/modules/preferences/utils';
import {featureToggle} from '../components/Common/FeatureToggle/FeatureToggleUtils/FeatureToggleUtils';
import {FEATURES} from '../constants/features';

const messages = defineMessages({
  allCategoriesLabel: {
    id: 'preferences.allCategoriesLabel',
    defaultMessage: 'All'
  },
  SMS: {
    id: 'preferences.SMS',
    defaultMessage: 'SMS'
  },
  EMAIL: {
    id: 'preferences.EMAIL',
    defaultMessage: 'Email'
  },
  HIGHLY_PREFERRED: {
    id: 'preferences.HIGHLY_PREFERRED',
    defaultMessage: 'Highly Preferred'
  },
  MODERATELY_PREFERRED: {
    id: 'preferences.MODERATELY_PREFERRED',
    defaultMessage: 'Moderately Preferred'
  },
  NOT_PREFERRED: {
    id: 'preferences.NOT_PREFERRED',
    defaultMessage: 'Not Preferred'
  },
  NOT_RATED: {
    id: 'preferences.NOT_RATED',
    defaultMessage: 'Not Rated'
  },
  required: {
    id: 'preferences.NotificationTriggerSelector.required',
    defaultMessage: 'This input is required'
  },
  LOW: {
    id: 'preferences.LOW',
    defaultMessage: 'Low'
  },
  MODERATE: {
    id: 'preferences.MODERATE',
    defaultMessage: 'Moderate'
  },
  HIGH: {
    id: 'preferences.HIGH',
    defaultMessage: 'High'
  },
  SEVERE: {
    id: 'preferences.SEVERE',
    defaultMessage: 'Severe'
  },
  MINIMAL: {
    id: 'preferences.MINIMAL',
    defaultMessage: 'Minimal'
  },
});

const showPersonLocationTypesDropdown = featureToggle(FEATURES.PRM_764);

export function formatCategories(categories, alertCategoriesDict) {
  return categories?.map(categoryId => {
    if (categoryId === ALL_CATEGORIES_GUID) {
      return {
        id: categoryId,
        name: <FormattedMessage {...messages.allCategoriesLabel}/>,
        value: categoryId
      };
    }
    return alertCategoriesDict[categoryId] || {id: categoryId, name: categoryId, value: categoryId};
  });
}

export function formatSeverities(severities) {
  return severities?.map(severity => ({
    name: <FormattedMessage {...ALERT_SEVERITIES[severity.toUpperCase()].message}/>,
    id: ALERT_SEVERITIES[severity.toUpperCase()].severityValue
  }));
}

export function formatImpactLevels(impactLevels) {
  return impactLevels?.map(impactLevel => ({
    name: <FormattedMessage {...messages[impactLevel.toUpperCase()]}/>,
    id: impactLevel
  }));
}

export function formatModalities(modalities) {
  return modalities.map(modality => {
    return {
      id: modality.type,
      name: <FormattedMessage {...messages[modality.type]}/>,
    }
  })
}

export function formatLocations(locations, locationsDict) {
  return locations?.map(locationId => {
    const loc = locationsDict[locationId]
    if (!loc) {
      return {};
    }
    return {
      id: loc.value,
      name: loc.name,
      title: loc.name,
      label: loc.name,
      value: loc.value
    }
  });
}

export const hasValues = (details) => _.values(details).reduce((accumulator, value) => accumulator || !!value, false);

export const NOTIFICATION_TRIGGERS = {
  AIRLINE_PREFERENCE_RATINGS: 'airlinePreferenceRatings',
  AIRLINES: 'airlines'
};

export const NOTIFICATION_TRIGGER_SELECTOR_FIELD_NAMES = {
  NOTIFICATION_SELECTOR: 'notificationSelector',
  AIRLINE_PREFERENCE_RATING: 'airlinePreferenceRatings',
  LOCATION_IDS: 'locationIds',
  AIRLINE_IDS: 'airlineIds'
};

export const getAirlinePreferenceRatingsOptions = () => {
  return Object.keys(AIRLINE_RATINGS).map((key) => {

    const message = messages[key];
    return ({
      value: key,
      label: <FormattedMessage {...message} />
    });
  }
  );
};

export const getMaybeRequiredValidator = (notificationTriggerType, siblings = []) => ({getFieldValue}) => ({
  validator(_field, value) {
    const fieldValue = getFieldValue(NOTIFICATION_TRIGGER_SELECTOR_FIELD_NAMES.NOTIFICATION_SELECTOR);
    const isNotMatchingTrigger = fieldValue !== notificationTriggerType;
    const hasValue = value && !_.isEmpty(value);

    const atLeastOneSiblingWithValue = !_.isEmpty(siblings)
      ? siblings.map(
        (siblingName) => getFieldValue(siblingName)
      )
        .reduce(
          (accumulator, sibling) => accumulator || (sibling && !_.isEmpty(sibling)),
          false

        )
      : false;

    if (hasValue || isNotMatchingTrigger || atLeastOneSiblingWithValue) {
      return Promise.resolve();
    }
    return Promise.reject(<FormattedMessage {...messages.required}/>);
  }
});

export const buildNonPreferredAirlinePreference = (preference, airlines, locationsDict, ratings) => {
  const {config, id, name, valid} = preference;
  const {airlinePreferenceRatings, locationIds, airlineIds} = config;

  const ratingList = !_.isEmpty(airlinePreferenceRatings) &&
    ratings.filter((rating) => airlinePreferenceRatings.includes(rating.value))
      .map((rating) => ({id: rating.value, name: rating.label}));

  return {
    id,
    name,
    valid,
    locationIds,
    airlinePreferenceRatings,
    airlineIds,
    airlinePreferenceRatingTags: ratingList,
    locations: !_.isEmpty(locationIds) ? formatLocations(locationIds, locationsDict): undefined,
    airlines: airlines?.map(({id, iataCode, name}) => ({id, name: `${iataCode} - ${name}`}))
  };
};

export const locationsKeyMap = {
  INCIDENT_NOTIFICATION: 'locationIds',
  ALERT_NOTIFICATION: 'locations',
};

export const categoriesKeyMap = {
  INCIDENT_NOTIFICATION: 'categoryIds',
  ALERT_NOTIFICATION: 'categories',
};

export const buildMappedPreference = (preference, categoriesDict, locationsDict) => {
  const {config, id, name, valid, type} = preference;

  const isIncident = type === NOTIFICATION_TYPES.INCIDENT_NOTIFICATION;
  const isAlert = type === NOTIFICATION_TYPES.ALERT_NOTIFICATION;

  const formatPreferenceLocations = (locationKey) => {
    return formatLocations(config[locationKey], locationsDict);
  };

  const formatPreferenceCategories = (categoriesKey) => {
    return formatCategories(config[categoriesKey], categoriesDict);
  };

  const formatSitesAffected = (sites) => {
    return sites?.map(site => ({
      ...site,
      value: site.id,
      label: site.name
    }));
  };

  const formatDuration = (duration) => {
    return {
      startAt: moment(duration.startAt),
      endAt: moment(duration.endAt)
    };
  };

  const formatPeopleAffected = (peopleAffected) => {
    if (peopleAffected) {
      const formattedPersonLocationTypes = peopleAffected?.personLocationTypes?.map(type => inversePersonLocationTypeMap[type]).filter(Boolean);
      return {
        ...peopleAffected,
        personLocationTypes: {
          personGroup: [
            ...(peopleAffected?.mobileLocationSubtypes ?? []),
            ...(peopleAffected?.tripSubtypes ?? []),
            ...(formattedPersonLocationTypes ?? [])
          ]
        }
      };
    }
  };

  const locationKey = locationsKeyMap?.[type];
  const categoriesKey = categoriesKeyMap?.[type];

  const baseConfig = {
    id,
    name,
    valid,
    [locationKey]: formatPreferenceLocations(locationKey),
    [categoriesKey]: formatPreferenceCategories(categoriesKey),
    alwaysSendUniqueNotification: config?.alwaysSendUniqueNotification,
    modalities: formatModalities(config.modalities),
    sitesAffected: config?.sitesAffected ? {
      ...config.sitesAffected,
      siteIds: formatSitesAffected(preference?.sites),
    } : undefined,
  };

  if (isIncident) {
    baseConfig.impactLevels = formatImpactLevels(config.impactLevels);
  }

  if (isAlert) {
    baseConfig.severities = formatSeverities(config.severities);
    baseConfig.peopleAffected = showPersonLocationTypesDropdown ? formatPeopleAffected(config.peopleAffected) : config.peopleAffected
    baseConfig.organizations = config.organizations;
  }

  if (config.duration) {
    baseConfig.duration = formatDuration(config.duration);
  }

  return baseConfig;
};
