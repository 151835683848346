export const VIEW_ALL_USERS = 'VIEW_ALL_USERS';
export const VIEW_ALL_CLIENTS = 'VIEW_ALL_CLIENTS';
export const VIEW_ALL_ROLES = 'VIEW_ALL_ROLES';
export const MANAGE_USERS = 'MANAGE_USERS';
export const MANAGE_APP_USERS = 'MANAGE_APP_USERS';
export const MANAGE_ALL_USERS = 'MANAGE_ALL_USERS';
export const MANAGE_ALL_CLIENTS = 'MANAGE_ALL_CLIENTS';
export const VIEW_ORGANIZATION = 'VIEW_ORGANIZATION';
export const VIEW_USERS = 'VIEW_USERS';
export const VIEW_CLIENT_SUBSCRIPTIONS = 'VIEW_CLIENT_SUBSCRIPTIONS';
export const VIEW_CLIENT_ORGANIZATION_ROLES = 'VIEW_CLIENT_ORGANIZATION_ROLES';
export const VIEW_CRITICAL_ALERTS = 'VIEW_CRITICAL_ALERTS';
export const VIEW_WARNING_ALERTS = 'VIEW_WARNING_ALERTS';
export const VIEW_CAUTION_ALERTS = 'VIEW_CAUTION_ALERTS';
export const VIEW_INFORMATIONAL_ALERTS = 'VIEW_INFORMATIONAL_ALERTS';
export const VIEW_HISTORICAL_ALERTS = 'VIEW_HISTORICAL_ALERTS';
export const VIEW_HISTORICAL_INCIDENTS = 'VIEW_HISTORICAL_INCIDENTS'
export const DOWNLOAD_ALERTS = 'DOWNLOAD_ALERTS';
export const DOWNLOAD_INCIDENTS = 'DOWNLOAD_INCIDENTS';
export const MANAGE_USER_SMS_ALERT_SUBSCRIPTIONS = 'MANAGE_USER_SMS_ALERT_SUBSCRIPTIONS';
export const MANAGE_USER_EMAIL_ALERT_SUBSCRIPTIONS = 'MANAGE_USER_EMAIL_ALERT_SUBSCRIPTIONS';
export const MANAGE_USER_LOCATION_BASED_ALERT_SUBSCRIPTIONS = 'MANAGE_USER_LOCATION_BASED_ALERT_SUBSCRIPTIONS';
export const MANAGE_USER_MOBILE_ALERT_SUBSCRIPTIONS = 'MANAGE_USER_MOBILE_ALERT_SUBSCRIPTIONS';
export const MANAGE_DIB_SUBSCRIPTIONS = 'MANAGE_DIB_SUBSCRIPTIONS';
export const VIEW_ADVICE_SHEETS = 'VIEW_ADVICE_SHEETS';
export const VIEW_LOCATIONS = 'VIEW_LOCATIONS';
export const VIEW_APP_USERS  = 'VIEW_APP_USERS';
export const VIEW_CATEGORIES = 'VIEW_CATEGORIES';
export const VIEW_BRIEFS = 'VIEW_BRIEFS';
export const VIEW_ORGANIZATION_HIERARCHY = 'VIEW_ORGANIZATION_HIERARCHY';
export const VIEW_REGISTRATION_POLICIES = 'VIEW_REGISTRATION_POLICIES';
export const MANAGE_REGISTRATION_POLICIES = 'MANAGE_REGISTRATION_POLICIES';
export const VIEW_SECURITY_OUTLOOK = 'VIEW_SECURITY_OUTLOOK';
export const SUBMIT_ACCESS_TO_ANALYST = 'SUBMIT_ACCESS_TO_ANALYST';
export const ALLOW_LOCATION_SHARING = 'ALLOW_LOCATION_SHARING';
export const VIEW_PERSONS_MOBILE_LOCATION = 'VIEW_PERSONS_MOBILE_LOCATION';
export const VIEW_TRIPS = 'VIEW_TRIPS';
export const VIEW_MY_TRIPS = 'VIEW_MY_TRIPS';
export const MANAGE_TRIPS = 'MANAGE_TRIPS';
export const MANAGE_MY_TRIPS = 'MANAGE_MY_TRIPS';
export const BULK_UPLOAD_TRIPS = 'BULK_UPLOAD_TRIPS';
export const VIEW_RESOURCES = 'VIEW_RESOURCES';
export const MANAGE_RESOURCES = 'MANAGE_RESOURCES';
export const VIEW_CLIENT_LOGOS = 'VIEW_CLIENT_LOGOS';
export const MANAGE_CLIENT_LOGOS = 'MANAGE_CLIENT_LOGOS';
export const VIEW_DATA_RETENTION_POLICIES = 'VIEW_DATA_RETENTION_POLICIES';
export const EDIT_DATA_RETENTION_POLICIES = 'EDIT_DATA_RETENTION_POLICIES';
export const MANAGE_LANDING_PAGE = 'MANAGE_LANDING_PAGE';
export const MANAGE_EMAIL_TEMPLATES = 'MANAGE_EMAIL_TEMPLATES';
export const VIEW_EMAIL_TEMPLATES = 'VIEW_EMAIL_TEMPLATES';
export const VIEW_SAFETY_CHECKIN_SIGNAL_LOCATIONS = 'VIEW_SAFETY_CHECKIN_SIGNAL_LOCATIONS';
export const VIEW_CRISIS_SIGNAL_LOCATIONS = 'VIEW_CRISIS_SIGNAL_LOCATIONS';
export const VIEW_WORLD_AIRLINE_MONITOR = 'VIEW_WORLD_AIRLINE_MONITOR';
export const MANAGE_AIRLINE_CUSTOM_RATINGS = 'MANAGE_AIRLINE_CUSTOM_RATINGS';
export const SEND_DIRECT_MESSAGES = 'SEND_DIRECT_MESSAGES';
export const SEND_MASS_NOTIFICATION = 'SEND_MASS_NOTIFICATION';
export const MANAGE_MASS_NOTIF_TEMPLATES = 'MANAGE_MASS_NOTIF_TEMPLATES';
export const MANAGE_CLIENT_TRIP_NOTIFICATION_CONFIGURATIONS = 'MANAGE_CLIENT_TRIP_NOTIFICATION_CONFIGURATIONS';
export const VIEW_CLIENT_TRIP_NOTIFICATION_CONFIGURATIONS = 'VIEW_CLIENT_TRIP_NOTIFICATION_CONFIGURATIONS';
export const MANAGE_CLIENT_EXPAT_NOTIFICATION_CONFIGURATIONS = 'MANAGE_CLIENT_EXPAT_NOTIFICATION_CONFIGURATIONS';
export const VIEW_CLIENT_EXPAT_NOTIFICATION_CONFIGURATIONS = 'VIEW_CLIENT_EXPAT_NOTIFICATION_CONFIGURATIONS';
export const VIEW_ALL_PNRS = 'VIEW_ALL_PNRS';
export const VIEW_AIRLINES = 'VIEW_AIRLINES';
export const VIEW_TRIP_EMAIL_PARSING = 'VIEW_TRIP_EMAIL_PARSING';
export const MANAGE_USER_EMAIL_INCIDENT_SUBSCRIPTIONS = 'MANAGE_USER_EMAIL_INCIDENT_SUBSCRIPTIONS';
export const SELECT_MASS_NOTIF_TEMPLATES = 'SELECT_MASS_NOTIF_TEMPLATES';
export const MANAGE_STANDARD_TEMPLATE_CONFIG = 'MANAGE_STANDARD_TEMPLATE_CONFIG';
export const VIEW_HEALTH_OUTLOOK= 'VIEW_HEALTH_OUTLOOK';
export const VIEW_CUSTOM_EMAIL_SENT_FROM = 'VIEW_CUSTOM_EMAIL_SENT_FROM';
export const MANAGE_CUSTOM_EMAIL_SENT_FROM = 'MANAGE_CUSTOM_EMAIL_SENT_FROM';
export const MANAGE_CUSTOM_EMAIL_DOMAIN = 'MANAGE_CUSTOM_EMAIL_DOMAIN';
export const MANAGE_PERSON_CUSTOM_FIELD_CONFIGURATIONS = 'MANAGE_PERSON_CUSTOM_FIELD_CONFIGURATIONS';
export const VIEW_MY_ORGANIZATION_CONFIGURATIONS = 'VIEW_MY_ORGANIZATION_CONFIGURATIONS';
export const MANAGE_TRIP_CUSTOM_FIELD_CONFIGURATIONS = 'MANAGE_TRIP_CUSTOM_FIELD_CONFIGURATIONS';
export const VIEW_ORGANIZATION_RECIPIENTS = 'VIEW_ORGANIZATION_RECIPIENTS';
export const MANAGE_ORGANIZATION_RECIPIENTS = 'MANAGE_ORGANIZATION_RECIPIENTS';
export const VIEW_ORGANIZATION_RECIPIENT_SCHEDULED_REPORTS = 'VIEW_ORGANIZATION_RECIPIENT_SCHEDULED_REPORTS';
export const MANAGE_ORGANIZATION_RECIPIENT_SCHEDULED_REPORTS = 'MANAGE_ORGANIZATION_RECIPIENT_SCHEDULED_REPORTS';

export const VIEW_LARGE_SCALE_EVENT_ANALYSIS = 'VIEW_LARGE_SCALE_EVENT_ANALYSIS';

// IMPERSONATION
export const IMPERSONATE_ALL_USERS = 'IMPERSONATE_ALL_USERS';
export const IMPERSONATE_USERS = 'IMPERSONATE_USERS';
export const IMPERSONATE_USERS_IN_MY_ORGANIZATION = 'IMPERSONATE_USERS_IN_MY_ORGANIZATION';

export const VIEW_UKRAINE_CONFLICT_REPORT = 'VIEW_UKRAINE_CONFLICT_REPORT';

//INTEL
export const VIEW_COUNTRY_INTEL = 'VIEW_COUNTRY_INTEL';
export const VIEW_PROVINCE_INTEL = 'VIEW_PROVINCE_INTEL';
export const VIEW_CITY_INTEL = 'VIEW_CITY_INTEL';
export const DOWNLOAD_LOCATION_INTEL = 'DOWNLOAD_LOCATION_INTEL';
export const VIEW_LOCATION_INTEL_MATRIX = 'VIEW_LOCATION_INTEL_MATRIX';
export const VIEW_PARENT_CATEGORY_INTEL = 'VIEW_PARENT_CATEGORY_INTEL';
export const VIEW_SUB_CATEGORY_INTEL = 'VIEW_SUB_CATEGORY_INTEL';
export const AXA_MEDICAL_PROVIDER_SEARCH = 'AXA_MEDICAL_PROVIDER_SEARCH';
export const VIEW_THREAT_ZONES = 'VIEW_THREAT_ZONES';
export const PROVIDER_SEARCH = 'PROVIDER_SEARCH';
export const VIEW_INCIDENTS = 'VIEW_INCIDENTS';
export const VIEW_KEYWORDS = 'VIEW_KEYWORDS';
export const IMG_PROVIDER_SEARCH = 'IMG_PROVIDER_SEARCH';
export const VIEW_AXA_MY_TRIP = 'VIEW_AXA_MY_TRIP';

//RATINGS
export const VIEW_COUNTRY_RATINGS = 'VIEW_COUNTRY_RATINGS';
export const VIEW_PROVINCE_RATINGS = 'VIEW_PROVINCE_RATINGS';
export const VIEW_CITY_RATINGS = 'VIEW_CITY_RATINGS';
export const VIEW_PARENT_CATEGORY_RATINGS = 'VIEW_PARENT_CATEGORY_RATINGS';
export const VIEW_SUB_CATEGORY_RATINGS = 'VIEW_SUB_CATEGORY_RATINGS';
export const MANAGE_RATINGS_CHANGE_NOTIFICATIONS = 'MANAGE_RATINGS_CHANGE_NOTIFICATIONS';
export const MANAGE_CLIENT_CONFIGURED_RISK_RATINGS = 'MANAGE_CLIENT_CONFIGURED_RISK_RATINGS';
export const UPLOAD_CLIENT_CONFIGURED_RISK_RATINGS = 'UPLOAD_CLIENT_CONFIGURED_RISK_RATINGS';
export const MANAGE_CLIENT_CONFIGURED_NOTICES = 'MANAGE_CLIENT_CONFIGURED_NOTICES';
export const VIEW_CLIENT_CONFIGURED_NOTICES = 'VIEW_CLIENT_CONFIGURED_NOTICES';
export const VIEW_CUSTOM_RATING_SCALE = 'VIEW_CUSTOM_RATING_SCALE';
export const MANAGE_CUSTOM_RATING_SCALE = 'MANAGE_CUSTOM_RATING_SCALE';

//PERSONS
export const VIEW_PERSONS = 'VIEW_PERSONS';
export const MANAGE_PERSONS = 'MANAGE_PERSONS';
export const BULK_PERSONS_UPLOAD = 'BULK_PERSONS_UPLOAD';
export const VIEW_PERSON_TYPES = 'VIEW_PERSON_TYPES';
export const MANAGE_PERSON_TYPES = 'MANAGE_PERSON_TYPES';
export const MANAGE_DELETED_PERSONS = 'MANAGE_DELETED_PERSONS';
export const VIEW_DELETED_PERSONS = 'VIEW_DELETED_PERSONS';
export const VIEW_PERSONS_GEOCODED_ADDRESS = 'VIEW_PERSONS_GEOCODED_ADDRESS';
export const VIEW_MY_EXPATRIATION = 'VIEW_MY_EXPATRIATION';
export const MANAGE_MY_EXPATRIATION = 'MANAGE_MY_EXPATRIATION';
export const VIEW_EXPATRIATIONS = 'VIEW_EXPATRIATIONS';
export const MANAGE_EXPATRIATIONS = 'MANAGE_EXPATRIATIONS';
export const BULK_UPLOAD_EXPATRIATIONS = 'BULK_UPLOAD_EXPATRIATIONS';
export const MANAGE_PERSON_DATA_FEED_CONFIGURATION = 'MANAGE_PERSON_DATA_FEED_CONFIGURATION';
export const VIEW_PERSON_DATA_FEED_CONFIGURATION = 'VIEW_PERSON_DATA_FEED_CONFIGURATION';
export const MANAGE_GROUPS = 'MANAGE_GROUPS';
export const MERGE_PERSONS = 'MERGE_PERSONS';
export const VIEW_ORGANIZATION_POST_BOOKING_APPROVAL = 'VIEW_ORGANIZATION_POST_BOOKING_APPROVAL';
export const MANAGE_ORGANIZATION_POST_BOOKING_APPROVAL = 'MANAGE_ORGANIZATION_POST_BOOKING_APPROVAL';
export const VIEW_PEOPLE_DEVICES = 'VIEW_PEOPLE_DEVICES';

//SITES
export const VIEW_SITES = 'VIEW_SITES';
export const MANAGE_SITES = 'MANAGE_SITES';

export const VIEW_SITE_POC = 'VIEW_SITE_POC';
export const MANAGE_SITE_POC = 'MANAGE_SITE_POC';
export const VIEW_OCCUPANTS = 'VIEW_OCCUPANTS';
export const MANAGE_OCCUPANTS = 'MANAGE_OCCUPANTS';
export const BULK_UPLOAD_SITES = 'BULK_UPLOAD_SITES';
export const TRAVEL_MANAGER_TRIP_BULK_UPLOAD = 'TRAVEL_MANAGER_TRIP_BULK_UPLOAD';
export const VIEW_SITES_DATA_FEED_CONFIGURATION = 'VIEW_SITES_DATA_FEED_CONFIGURATION';
export const MANAGE_SITES_DATA_FEED_CONFIGURATION = 'MANAGE_SITES_DATA_FEED_CONFIGURATION';

//REPORTS
export const VIEW_ALL_REPORTS = 'VIEW_ALL_REPORTS';
export const VIEW_REPORTS = 'VIEW_REPORTS';
export const VIEW_INTERNAL_REPORTS = 'VIEW_INTERNAL_REPORTS';
export const DOWNLOAD_PEOPLE_REPORTS = 'DOWNLOAD_PEOPLE_REPORTS';
export const MANAGE_SCHEDULED_REPORTS = 'MANAGE_SCHEDULED_REPORTS';
export const MANAGE_INTERNAL_SCHEDULED_REPORTS = 'MANAGE_INTERNAL_SCHEDULED_REPORTS';

//E-Learning
export const VIEW_E_LEARNING = 'VIEW_E_LEARNING';

//Automated Check-ins
export const MANAGE_SCHEDULED_TRAVELER_CHECKIN_CONFIG = 'MANAGE_SCHEDULED_TRAVELER_CHECKIN_CONFIG';
export const VIEW_SCHEDULED_TRAVELER_CHECKIN_CONFIG = 'VIEW_SCHEDULED_TRAVELER_CHECKIN_CONFIG';

// Topo One
export const ACCESS_TO_TOPOONE = 'ACCESS_TO_TOPOONE';

// In App Messaging
export const MANAGE_IN_APP_MESSAGING = 'MANAGE_IN_APP_MESSAGING';
export const VIEW_IN_APP_MESSAGE_NOTIFICATIONS = 'VIEW_IN_APP_MESSAGE_NOTIFICATIONS';
export const INITIATE_IN_APP_MESSAGING = 'INITIATE_IN_APP_MESSAGING';

// Locale/Language permissions
export const POLISH = 'POLISH';
export const HINDI = 'HINDI';
export const ITALIAN = 'ITALIAN';
export const ENGLISH = 'ENGLISH';
export const RUSSIAN = 'RUSSIAN';
export const FRENCH = 'FRENCH';
export const GERMAN = 'GERMAN';
export const CHINESE_TRADITIONAL = 'CHINESE_TRADITIONAL';
export const CHINESE_SIMPLIFIED = 'CHINESE_SIMPLIFIED';
export const SPANISH = 'SPANISH';
export const PORTUGUESE = 'PORTUGUESE';
export const DUTCH = 'DUTCH';
export const JAPANESE = 'JAPANESE';
export const HEBREW = 'HEBREW';
export const TURKISH = 'TURKISH';
export const KOREAN = 'KOREAN';
export const GREEK = 'GREEK';
export const VIETNAMESE = 'VIETNAMESE';
export const CZECH = 'CZECH';
export const HUNGARIAN = 'HUNGARIAN';
export const MALAY = 'MALAY';
export const ROMANIAN = 'ROMANIAN';
export const SLOVAK = 'SLOVAK';
export const THAI = 'THAI';
export const UKRAINIAN = 'UKRAINIAN';

//GOC
export const MANAGE_CRITICAL_OUTREACH = 'MANAGE_CRITICAL_OUTREACH';
export const VIEW_CRITICAL_OUTREACH = 'VIEW_CRITICAL_OUTREACH';
export const INVOKE_CRITICAL_OUTREACH = 'INVOKE_CRITICAL_OUTREACH'

export const VIEW_ALERTS_PERMISSION_LIST = [
  VIEW_CRITICAL_ALERTS,
  VIEW_WARNING_ALERTS,
  VIEW_CAUTION_ALERTS,
  VIEW_INFORMATIONAL_ALERTS,
  VIEW_HISTORICAL_ALERTS
];

// We can remove these Companion permissions at a later time
export const MAP_INCIDENT_REPORTS_PERMISSION = 'Incident_Reports';
export const SITE_READ_ONLY_PERMISSION = 'FacilityRiskMgtReadOnly';
export const SITE_MANAGEMENT_PERMISSION = 'FacilityRiskMgtReadWrite';
export const SITE_OCCUPANTS_READ_ONLY_PERMISSION = 'SitesOccupantAssocReadOnly';

export const VIEW_WORLD_MAP_PERMISSION_LIST = [
  // ALERT permissions
  VIEW_CRITICAL_ALERTS,
  VIEW_WARNING_ALERTS,
  VIEW_CAUTION_ALERTS,
  VIEW_INFORMATIONAL_ALERTS,
  // INCIDENT permissions
  MAP_INCIDENT_REPORTS_PERMISSION,
  // SITE permissions
  SITE_READ_ONLY_PERMISSION,
  // RATING permissions
  VIEW_COUNTRY_RATINGS,
  VIEW_PROVINCE_RATINGS,
  VIEW_CITY_RATINGS,
  // THREAT_ZONE permissions
  VIEW_THREAT_ZONES,
  // PERSONS permissions
  VIEW_TRIPS,
  VIEW_MY_TRIPS,
  VIEW_PERSONS_GEOCODED_ADDRESS,
  VIEW_PERSONS_MOBILE_LOCATION,
  VIEW_SAFETY_CHECKIN_SIGNAL_LOCATIONS,
  VIEW_CRISIS_SIGNAL_LOCATIONS,
  VIEW_EXPATRIATIONS,
  // LOCATION INTEL permissions
  VIEW_COUNTRY_INTEL,
  VIEW_PROVINCE_INTEL,
  VIEW_CITY_INTEL
];

export const VIEW_PERSON_EXPOSURES_PERMISSION_LIST = [
  VIEW_PERSONS_MOBILE_LOCATION,
  VIEW_CRISIS_SIGNAL_LOCATIONS,
  VIEW_SAFETY_CHECKIN_SIGNAL_LOCATIONS,
  VIEW_PERSONS_GEOCODED_ADDRESS,
  VIEW_TRIPS,
  VIEW_EXPATRIATIONS
];

export const VIEW_EXPOSURES_PERMISSION_LIST = [
  ...VIEW_PERSON_EXPOSURES_PERMISSION_LIST,
  VIEW_SITES
]

export const MANAGE_INTEL_DASHBOARD_CONFIGS = 'MANAGE_INTEL_DASHBOARD_CONFIGS';
export const VIEW_LARGE_SCALE_EVENT_DASHBOARD = 'VIEW_LARGE_SCALE_EVENT_DASHBOARD';

export const VIEW_PEOPLE_RISK_METRICS = 'VIEW_PEOPLE_RISK_METRICS';
export const VIEW_TRIP_EMAIL_PARSING_REPORTS = 'VIEW_TRIP_EMAIL_PARSING_REPORTS';

export const VIEW_LOCKED_FIELDS = 'VIEW_LOCKED_FIELDS'
export const MANAGE_LOCKED_FIELDS = 'MANAGE_LOCKED_FIELDS'

export const VIEW_AIRLINE_SAFETY = 'VIEW_AIRLINE_SAFETY'

export const MANAGE_MFA = 'MANAGE_MFA';
export const VIEW_MFA = 'VIEW_MFA';

export const VIEW_ORGANIZATION_SFTP_CONFIGURATIONS = 'VIEW_ORGANIZATION_SFTP_CONFIGURATIONS';
export const MANAGE_ORGANIZATION_SFTP_CONFIGURATIONS = 'MANAGE_ORGANIZATION_SFTP_CONFIGURATIONS';